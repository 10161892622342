import React from 'react'
import { useTranslation } from 'react-i18next'
import SlotPickerLater from '~/components/SlotPicker/SlotPickerLater'
import SlotPickerNow from '~/components/SlotPicker/SlotPickerNow'
import CheckboxINeedAnInvoice from '~/routes/($lang).subscription/payment/PaymentForm/CheckboxINeedAnInvoice'
import { type SlotPickContext, type SlotPickWhen } from './SlotPicker.types'
import SlotPickerWhen from './SlotPickerWhen'
import SlotsAsideResume from './SlotsAsideResume'

export type SlotPickerProps = {
  context: SlotPickContext
  isLogged: boolean
  isInvoiceNeededDefaultValue?: boolean
}

const SlotPicker = ({
  context,
  isLogged,
  isInvoiceNeededDefaultValue = false
}: SlotPickerProps) => {
  const { t } = useTranslation(['booking'])
  const [pickWhen, setPickWhen] = React.useState<SlotPickWhen>('now')

  const [isInvoiceNeeded, setIsInvoiceNeeded] = React.useState(
    isInvoiceNeededDefaultValue
  )

  const handleINeedAnInvoice = (checked: boolean) => {
    setIsInvoiceNeeded(checked)
  }

  return (
    <div className="mx-auto flex w-full max-w-screen-lg flex-col gap-y-8">
      <div className="grid gap-x-5 gap-y-14 lg:grid-cols-[35%,auto]">
        <div className="flex flex-col gap-y-4">
          <SlotsAsideResume
            context={context}
            isExcludingTaxVisible={isInvoiceNeeded}
          />
          <CheckboxINeedAnInvoice
            isDefaultChecked={isInvoiceNeeded}
            onChange={handleINeedAnInvoice}
          />
        </div>
        <div className="flex flex-col gap-y-4">
          <SlotPickerWhen value={pickWhen} onChange={setPickWhen} />
          <div className="border-black/10 md:rounded-md md:border-1">
            {pickWhen === 'now' ? (
              <SlotPickerNow
                context={context}
                isInvoiceNeeded={isInvoiceNeeded}
                isLogged={isLogged}
                validateButtonLabel={t(
                  isLogged
                    ? 'booking:proceedToPayment'
                    : 'booking:proceedToRegistration'
                )}
              />
            ) : (
              <SlotPickerLater
                context={context}
                isInvoiceNeeded={isInvoiceNeeded}
                isLogged={isLogged}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlotPicker
