import { useTranslation } from 'react-i18next'
import Typography from '~/components/Typography'
import AsideResumePrice from '~/routes/($lang).subscription/payment/AsideResume/AsideResumePrice'
import CheckUpContentsDetails from '../CheckUpContentsDetails'
import ResumeOffer from '../ResumeOffer'
import type { SlotPickContext } from '../SlotPicker.types'

export type SlotsAsideResumeProps = {
  context: SlotPickContext
  isExcludingTaxVisible: boolean
}

const SlotsAsideResume = ({
  context,
  isExcludingTaxVisible
}: SlotsAsideResumeProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center gap-y-6">
      <div className="flex w-full flex-col rounded-md border-1 border-black/10 max-lg:text-center max-sm:text-left">
        <div className="flex flex-col gap-y-6 p-8">
          <div className="flex flex-col gap-y-2">
            <Typography variant="h6" component="h3" family="brand">
              {t('common:subscription')}
            </Typography>
            <Typography variant="h6" component="p" color="shy">
              {t('common:annualPayment')}
            </Typography>
          </div>
          <AsideResumePrice isExcludingTaxVisible={isExcludingTaxVisible} />
          <ResumeOffer />
        </div>
        <CheckUpContentsDetails context={context} />
      </div>
    </div>
  )
}

export default SlotsAsideResume
